input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.stepex-form-label {
  color: #595959;
}

.stepex-form-label-mandatory {
  color: #ff4d4f;
}

.stepex-form-label-ok {
  color: #6aaa59;
}
